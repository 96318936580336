@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Inter");

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-container {
  margin-left: 16rem;
  margin-right: 2rem;
  width: calc(100vw - 19rem);
}

/* override default nav bar style */
.sidenav---sidenav-navitem---uwIJ- {
  margin: auto 1rem;
}

.sidenav---sidenav---_2tBP {
  background: linear-gradient(180deg, #3e54ac 0%, #655dbb 100%) !important;
  position: fixed !important;
}

span.nav-bar-menu {
  color: theme("colors.white") !important;
}

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u
  > .sidenav---navitem---9uL5T {
  background: theme("colors.hoverBlue");
  border-right: 2px solid white;
  border-bottom: 2px solid theme("colors.hoverBlue");
  border-left: 2px solid theme("colors.hoverBlue");
  border-top: 2px solid theme("colors.hoverBlue");
  border-radius: 10px;
}

.custom-width-100-2 {
  width: calc(100% - 2rem);
}

.custom-height-100-2 {
  height: calc(100% - 2rem);
}
